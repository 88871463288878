import React from 'react'
import posed, { PoseGroup } from 'react-pose'
import PropTypes from 'prop-types'

import styles from './overlay.module.scss'
import buttonstyles from './button.module.scss'

import { navigate } from 'gatsby'
import matches from '@sub-lunar/matches'

const transition = {
  duration: 300,
  ease: 'easeOut',
}

const Wrapper = posed.div({
  enter: {
    opacity: 1,
    transition,
  },
  exit: {
    opacity: 0,
    transition,
  },
})

const Content = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})

class Overlay extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mousedown: null,
    }
  }

  mouseDownHandler(event) {
    this.setState({
      mouseDown: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  }

  mouseUpHandler(event) {
    // do not close when an anchor is clicked or the mouse was moved

    const xDist = Math.abs(this.state.mouseDown.x - event.clientX)
    const yDist = Math.abs(this.state.mouseDown.y - event.clientY)

    if (xDist > 12 || yDist > 12) {
      return
    }

    if (matches(event.target, 'a')) {
      return
    }

    this.props.closeButtonSideEffectsHandler()
    navigate('/')
  }

  closeButtonClickHandler() {
    this.props.closeButtonSideEffectsHandler()
    navigate('/')
  }

  render() {
    const isVisible = this.props.location.pathname !== '/'

    return (
      <PoseGroup>
        {isVisible && (
          <Wrapper
            className={styles.wrapper1}
            key="wrapper"
            onMouseUp={event => this.mouseUpHandler(event)}
            onMouseDown={event => this.mouseDownHandler(event)}
          >
            <div className={styles.wrapper2}>
              <PoseGroup>
                <Content
                  className={styles.content}
                  key={this.props.location.pathname}
                  withParent={false}
                >
                  {this.props.children}
                </Content>
              </PoseGroup>
            </div>
            <button
              type="button"
              className={`${buttonstyles.overlay} ${styles.close}`}
              onClick={() => this.closeButtonClickHandler}
            >
              Close
            </button>
          </Wrapper>
        )}
      </PoseGroup>
    )
  }
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  closeButtonSideEffectsHandler: PropTypes.func.isRequired,
}

export default Overlay
