import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link, navigate, StaticQuery } from 'gatsby'
import debounce from 'lodash.debounce'
import posed, { PoseGroup } from 'react-pose'

import * as fullscreen from '../lib/fullscreen'

import Waveform from '../components/waveform'
import DancingVideo from '../components/dancing-video'
import Controlbar from '../components/controlbar'
import Menu from '../components/menu'
import About from '../components/about'
import Overlay from '../components/overlay'

import wp from '../../wp-export/all.json'

import './index.scss'
import styles from './logo.module.scss'

import { ReactComponent as Logo } from '../components/logo.svg'
import waveform from '../../wp-export/waveform.json'
import reel from '../../wp-export/showreel.mp4'

const BAR_WIDTH = 2 // unit: samples
const HORIZONTAL_STRETCH = 1 // scale factor
const MAX_DIFF = 0.005 // scale factor, will be multiplied by viewport height

const PAGES = [
  {
    path: '/',
    title: wp.siteTitle,
    keyCode: 72,
    regex: /^\/?$/,
  },
  {
    path: '/contact',
    title: `Contact | ${wp.siteTitle}`,
    keyCode: 67,
    regex: /^\/contact\/?$/,
  },
  {
    path: '/imprint',
    title: `Imprint | ${wp.siteTitle}`,
    keyCode: 73,
    regex: /^\/imprint\/?$/,
  },
  {
    path: '/privacy',
    title: `Datenschutz | ${wp.siteTitle}`,
    keyCode: 80,
    regex: /^\/privacy\/?$/,
  },
].map(page => ({
  ...page,
  handler: pathname =>
    !pathname.match(page.regex) ? navigate(page.path) : null,
}))

const aboutTransition = {
  duration: 300,
  ease: 'easeOut',
}

const AboutWrapper = posed.div({
  enter: {
    opacity: 1,
    aboutTransition,
  },
  exit: {
    opacity: 0,
    aboutTransition,
  },
})

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.debouncedResizeHandler = debounce(() => this.resizeHandler(), 300)
    this.boundCalcDimensions = () => this.calcDimensions()
    this.fullscreenEnterHandler = this.fullscreenEnterHandler.bind(this)
    this.fullscreenExitHandler = this.fullscreenExitHandler.bind(this)
    this.keydownHandler = this.keydownHandler.bind(this)
    this.fullscreenElement = React.createRef()
    this.video = React.createRef()
    this.state = {
      playPosition: 0,
      vpH: 100,
      vpW: 100,
      isPlaying: false,
      isBlind: false,
      isFullscreen: false,
      pathname: null,
      didPathnameChange: false,
      isLoading: true,
    }
    this.unsubFullscreenEnter = () => {}
    this.unsubFullscreenExit = () => {}
  }

  componentDidMount() {
    this.setState(this.getDimensions())
    window.addEventListener('resize', this.debouncedResizeHandler)
    window.addEventListener('keydown', this.keydownHandler)
    this.unsubFullscreenEnter = fullscreen.onEnter(this.fullscreenEnterHandler)
    this.unsubFullscreenExit = fullscreen.onExit(this.fullscreenExitHandler)
    this.setState({ isLoading: false })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debouncedResizeHandler)
    window.removeEventListener('keydown', this.keydownHandler)
    this.unsubFullscreenEnter()
    this.unsubFullscreenExit()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      return { pathname: props.location.pathname, didPathnameChange: true }
    }
    return { didPathnameChange: false }
  }

  componentDidUpdate() {
    if (this.state.didPathnameChange) {
      if (this.state.pathname === '/') {
        this.play()
      } else {
        this.pause()
      }
    }
  }

  resizeHandler() {
    this.setState(this.getDimensions())
  }

  getDimensions() {
    return { vpW: document.body.offsetWidth, vpH: window.innerHeight }
  }

  playPositionChangeHandler(playPosition) {
    this.setState({ playPosition })
  }

  makeSelectCurrentTimeHandler(seconds) {
    return () => this.video && this.video.current.setCurrentTime(seconds)
  }

  togglePlay() {
    if (this.state.isPlaying) {
      this.pause()
    } else {
      this.play()
    }
  }

  play() {
    if (!this.state.isPlaying) {
      this.setState({ isPlaying: true })
      this.video && this.video.current.play()
    }
  }

  pause() {
    if (this.state.isPlaying) {
      this.setState({ isPlaying: false })
      this.video && this.video.current.pause()
    }
  }

  toggleBlind() {
    if (!this.state.isBlind && this.state.isFullscreen) {
      fullscreen.exit()
    }
    this.setState({ isBlind: !this.state.isBlind })
  }

  toggleFullscreen() {
    if (this.state.isFullscreen) {
      fullscreen.exit()
    } else {
      fullscreen.enter(this.fullscreenElement.current)
    }
  }

  fullscreenEnterHandler() {
    this.setState({ isFullscreen: true })
  }

  fullscreenExitHandler() {
    this.setState({ isFullscreen: false })
  }

  logoClickHandler() {
    if (this.props.location.pathname === '/') {
      this.togglePlay()
    } else {
      navigate('/')
    }
  }

  keydownHandler({ keyCode }) {
    let mappings = [
      { keyCode: 27, handler: this.escapeHandler.bind(this) },
      { keyCode: 32, handler: this.spacebarHandler.bind(this) },
      { keyCode: 37, handler: this.arrowLeftHandler.bind(this) },
      { keyCode: 39, handler: this.arrowRightHandler.bind(this) },
      ...PAGES,
    ]
    for (let i = 0; i < mappings.length; i++) {
      if (mappings[i].keyCode === keyCode) {
        mappings[i].handler(this.props.location.pathname)
        return
      }
    }
  }

  escapeHandler() {
    if (this.props.location.pathname !== '/') {
      navigate('/')
    } else if (!this.state.isPlaying) {
      this.play()
    }
  }

  spacebarHandler() {
    // avoid double-trigger if play/pause button still has focus
    // (will be the case if user pause with the mouse)
    document.activeElement.blur()
    if (this.props.location.pathname === '/') {
      this.togglePlay()
    }
  }

  arrowLeftHandler() {
    if (!this.state.isPlaying || !wp.showreel.timeline.length || !this.video) {
      return
    }
    let i = 0
    while (
      i < wp.showreel.timeline.length &&
      wp.showreel.timeline[i].playPosition < this.state.playPosition - 0.01
    ) {
      i++
    }
    if (i > 0) {
      i--
    }
    this.video.current.setCurrentTime(wp.showreel.timeline[i].time)
  }

  arrowRightHandler() {
    if (!this.state.isPlaying || !wp.showreel.timeline.length || !this.video) {
      return
    }
    let i = 0
    while (
      i < wp.showreel.timeline.length &&
      wp.showreel.timeline[i].playPosition < this.state.playPosition
    ) {
      i++
    }
    if (i >= wp.showreel.timeline.length) {
      return
    }
    this.video.current.setCurrentTime(wp.showreel.timeline[i].time)
  }

  overlayCloseButtonSideEffectsHandler() {
    this.play()
  }

  render() {
    let title = Layout.getTitle(this.props.location.pathname)
    return (
      <>
        <Helmet
          title={title}
          meta={[
            { name: 'description', content: wp.siteDescription },
            { name: 'keywords', content: 'sample, something' },
            { property: 'og:type', content: 'website' },
            {
              property: 'og:title',
              content: title,
            },
            { property: 'og:description', content: wp.siteDescription },
            {
              property: 'og:url',
              content: wp.siteUrl + this.props.location.pathname,
            },
          ]
            .concat(
              wp.shareImage
                ? [
                    { property: 'og:image', content: wp.shareImage.url },
                    {
                      property: 'og:image:width',
                      content: wp.shareImage.width,
                    },
                    {
                      property: 'og:image:height',
                      content: wp.shareImage.height,
                    },
                  ]
                : []
            )
            .concat([
              { name: 'twitter:card', content: 'summary_large_image' },
              // TODO fill this in with sforce's OWN twitter account
              // {name: 'twitter:site', content: ''},
              { name: 'twitter:creator', content: '@buero_unfun' },
              {
                name: 'twitter:title',
                content: title,
              },
              { name: 'twitter:description', content: wp.siteDescription },
            ])
            .concat(
              wp.shareImage
                ? [{ name: 'twitter:image', content: wp.shareImage.url }]
                : []
            )}
        >
          <html lang="en" />
          <script type="text/javascript" src="/modernizr.js" />
        </Helmet>
        <div
          ref={this.fullscreenElement}
          className={`pageWrapper${this.state.isLoading ? ' isLoading' : ''}`}
        >
          <Waveform
            waveform={waveform}
            playPosition={this.state.playPosition}
            horizontalStretch={HORIZONTAL_STRETCH}
            barWidth={BAR_WIDTH}
            noDraw={this.state.isFullscreen}
            onClick={() => this.togglePlay()}
          />
          <DancingVideo
            ref={this.video}
            src={reel}
            waveform={waveform}
            aspectRatio={wp.showreel.width / wp.showreel.height}
            containerWidth={this.state.vpW}
            containerHeight={this.state.vpH}
            maxDiff={MAX_DIFF}
            loop={true}
            fullscreen={this.state.isFullscreen}
            blind={this.state.isBlind}
            onPlayPositionChange={pp => this.playPositionChangeHandler(pp)}
            onClick={() => this.togglePlay()}
          />
          <Controlbar
            timeline={wp.showreel.timeline}
            makeSelectCurrentTimeHandler={pp =>
              this.makeSelectCurrentTimeHandler(pp)
            }
            togglePlay={() => this.togglePlay()}
            toggleBlind={() => this.toggleBlind()}
            toggleFullscreen={() => this.toggleFullscreen()}
            isPlaying={this.state.isPlaying}
            isBlind={this.state.isBlind}
            isFullscreen={this.state.isFullscreen}
            playPosition={this.state.playPosition}
          />
          <Menu path={this.props.location.pathname} />
          <Logo
            onClick={() => this.logoClickHandler()}
            className={styles.logo}
          />
          <PoseGroup>
            {!this.state.isPlaying &&
              this.props.location.pathname === '/' && (
                <AboutWrapper key="about">
                  <About closeHandler={() => this.play()} />
                </AboutWrapper>
              )}
          </PoseGroup>

          <Overlay
            location={this.props.location}
            closeButtonSideEffectsHandler={() =>
              this.overlayCloseButtonSideEffectsHandler()
            }
          >
            {this.props.children}
          </Overlay>
        </div>
      </>
    )
  }

  static getTitle(pathname) {
    for (let i = 0; i < PAGES.length; i++) {
      if (pathname.match(PAGES[i].regex)) {
        return PAGES[i].title
      }
    }
    return wp.siteTitle
  }
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default Layout
