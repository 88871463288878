function pubsub() {
  let listeners = []
  return [
    listener => (
      listeners.push(listener),
      () => {
        let index = listeners.indexOf(listener)
        let found = index > -1
        if (found) {
          listeners.splice(listeners.indexOf(listener), 1)
        }
        return found
      }
    ),
    (...data) => {
      listeners.forEach(listener => listener(...data))
    },
  ]
}

let [onChange, publishChange] = pubsub()
let [onEnter, publishEnter] = pubsub()
let [onExit, publishExit] = pubsub()

function changeHandler() {
  publishChange()
  if (isOn()) {
    publishEnter()
  } else {
    publishExit()
  }
}

if (typeof document !== 'undefined') {
  let fsc = 'fullscreenchange'
  let prefixes = ['', 'webkit', 'moz', 'ms']
  for (let i = 0; i < prefixes.length; i++) {
    if ('on' + prefixes[i] + fsc in document) {
      document.addEventListener(prefixes[i] + fsc, changeHandler)
      break
    }
  }
}

function isAvailable() {
  return !!(
    typeof document !== 'undefined' &&
    ('requestFullscreen' in document.body ||
      'webkitRequestFullscreen' in document.body ||
      'mozRequestFullScreen' in document.body ||
      'msRequestFullscreen' in document.body)
  )
}

function isOn() {
  return !!(
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement ||
    document.msFullscreenElement
  )
}

function enter(element) {
  if (isOn()) {
    return
  }
  if ('requestFullscreen' in element) {
    element.requestFullscreen()
  } else if ('webkitRequestFullscreen' in element) {
    element.webkitRequestFullscreen()
  } else if ('mozRequestFullScreen' in element) {
    element.mozRequestFullScreen()
  } else if ('msRequestFullscreen' in element) {
    element.msRequestFullscreen()
  } else {
    return false
  }
  publishChange()
  publishEnter()
  return true
}

function exit() {
  if (!isOn()) {
    return
  }
  if ('exitFullscreen' in document) {
    document.exitFullscreen()
  } else if ('webkitExitFullscreen' in document) {
    document.webkitExitFullscreen()
  } else if ('mozCancelFullScreen' in document) {
    document.mozCancelFullScreen()
  } else if ('msExitFullscreen' in document) {
    document.msExitFullscreen()
  } else {
    return false
  }
  publishChange()
  publishExit()
  return true
}

export { onChange, onEnter, onExit, isAvailable, isOn, enter, exit }
