import React from 'react'
import styles from './eye-icon.module.scss'
import PropTypes from 'prop-types'

const EyeIcon = props => (
  <svg
    className={styles.svg}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 60"
  >
    <path d="M50 10.44c10.54 0 20.71 2.6 27.91 7.12C83.69 21.19 87 25.73 87 30s-3.31 8.81-9.09 12.44c-7.2 4.53-17.37 7.12-27.91 7.12s-20.71-2.6-27.91-7.12C16.31 38.81 13 34.27 13 30s3.31-8.81 9.09-12.44c7.2-4.53 17.37-7.12 27.91-7.12m0-10C24.04.44 3 13.67 3 30s21.04 29.56 47 29.56S97 46.33 97 30 75.96.44 50 .44z" />
    {props.isBlind ? (
      <path
        className={styles.line}
        transform="rotate(-61.477 50.001 30)"
        d="M45-22.35h10V82.36H45z"
      />
    ) : (
      <circle cx="50" cy="25" r="17" />
    )}
  </svg>
)

export default EyeIcon

EyeIcon.propTypes = {
  isBlind: PropTypes.bool.isRequired,
}
