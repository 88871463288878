import React from 'react'
import PropTypes from 'prop-types'

import * as fullscreen from '../lib/fullscreen'

import styles from './controlbar.module.scss'

import FullscreenIcon from './fullscreen-icon'
import TimelineButton from './timeline-button'

import { ReactComponent as MuteIcon } from './mute-icon.svg'
import EyeIcon from './eye-icon.js'
import { ReactComponent as PauseIcon } from './pause-icon.svg'
import { ReactComponent as PlayIcon } from './play-icon.svg'

import errorSound from '../../macos-error.mp3'

class Controlbar extends React.Component {
  constructor(props) {
    super(props)
    this.playErrorSound = this.playErrorSound.bind(this)
  }

  componentDidMount() {
    this.audio = new Audio(errorSound)
  }

  playErrorSound() {
    this.audio.play()
  }

  render() {
    return (
      <div className={styles.controlBar}>
        <div className={styles.progressBar}>
          <div className={styles.progressBarBar}>
            <div
              className={styles.progressBarProgress}
              style={{
                width: this.props.playPosition * 100 + '%',
              }}
            />
          </div>
          {this.props.timeline.map(
            ({ playPosition, seconds, timestamp, title }, index) => (
              <TimelineButton
                key={playPosition}
                playPosition={playPosition}
                index={index}
                title={title}
                timestamp={timestamp}
                clickHandler={this.props.makeSelectCurrentTimeHandler(seconds)}
              />
            )
          )}
        </div>

        <button
          className={styles.playButton}
          onClick={this.props.togglePlay}
          type="button"
        >
          {this.props.isPlaying ? (
            <PauseIcon className={styles.pauseIcon} />
          ) : (
            <PlayIcon className={styles.playIcon} />
          )}
        </button>
        <div
          className={styles.muteButton}
          onClick={() => this.playErrorSound()}
        >
          <MuteIcon className={styles.muteIcon} />
        </div>
        <button
          className={styles.blindButton}
          onClick={() => this.props.toggleBlind()}
          type="button"
        >
          <EyeIcon isBlind={this.props.isBlind} />
        </button>
        {fullscreen.isAvailable() ? (
          <button
            className={styles.fullscreenButton}
            onClick={() => this.props.toggleFullscreen()}
            type="button"
          >
            <FullscreenIcon isFullscreen={this.props.isFullscreen} />
          </button>
        ) : null}
      </div>
    )
  }
}

Controlbar.propTypes = {
  timeline: PropTypes.array.isRequired,
  makeSelectCurrentTimeHandler: PropTypes.func.isRequired,
  togglePlay: PropTypes.func.isRequired,
  toggleBlind: PropTypes.func.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  isPlaying: PropTypes.bool,
  isBlind: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  playPosition: PropTypes.number.isRequired,
}

export default Controlbar
