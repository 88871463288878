export function createShader(gl, type, source) {
  let shader = gl.createShader(type)
  gl.shaderSource(shader, source)
  gl.compileShader(shader)
  let success = gl.getShaderParameter(shader, gl.COMPILE_STATUS)
  if (success) {
    return shader
  }
  console.log(gl.getShaderInfoLog(shader))
  gl.deleteShader(shader)
}

export function createProgram(gl, vertexShader, fragmentShader) {
  let program = gl.createProgram()
  gl.attachShader(program, vertexShader)
  gl.attachShader(program, fragmentShader)
  gl.linkProgram(program)
  let success = gl.getProgramParameter(program, gl.LINK_STATUS)
  if (success) {
    return program
  }
  console.log(gl.getProgramInfoLog(program))
  gl.deleteProgram(program)
}

export function resizeCanvasToDisplaySize(canvas, multiplier = 1) {
  var width = (canvas.clientWidth * multiplier) | 0
  var height = (canvas.clientHeight * multiplier) | 0
  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width
    canvas.height = height
    return true
  }
  return false
}

export function uniform(gl, program, name, setterFn) {
  let location = gl.getUniformLocation(program, 'u_' + name)
  return setterFn.bind(gl, location)
}

export function uniforms(gl, program, definitions) {
  let key
  for (key in definitions) {
    if (definitions.hasOwnProperty(key)) {
      definitions[key] = uniform(gl, program, key, definitions[key])
    }
  }
  return definitions
}

export function boundBuffer(gl, array, typedArrayConstructor) {
  let buffer = gl.createBuffer()
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
  let typedArray = new typedArrayConstructor(array)
  gl.bufferData(gl.ARRAY_BUFFER, typedArray, gl.STATIC_DRAW)
  return buffer
}

export function readVertexAttrib(gl, buffer, location, size, type) {
  gl.enableVertexAttribArray(location)
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
  let _size = size || 2
  let _type = type || gl.FLOAT
  let normalize = false
  let stride = 0
  let offset = 0
  gl.vertexAttribPointer(location, _size, _type, normalize, stride, offset)
}

export function drawTriangles(gl, count) {
  let primitiveType = gl.TRIANGLES
  let _offset = 0
  gl.drawArrays(primitiveType, _offset, count)
}
