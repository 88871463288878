import React from 'react'
import { Link } from 'gatsby'
// import PropTypes from 'prop-types'
import styles from './menu.module.scss'
import buttonstyles from './button.module.scss'

const Menu = ({ path }) => {
  const buttonstyle = path === '/' ? buttonstyles.default : buttonstyles.overlay
  return (
    <div className={styles.menu}>
      <Link
        to="/contact/"
        activeClassName={buttonstyles.active}
        className={`${styles.link} ${buttonstyle}`}
      >
        Contact
      </Link>
      <Link
        to="/imprint/"
        activeClassName={buttonstyles.active}
        className={`${styles.link} ${buttonstyle} ${buttonstyles.dimmed}`}
      >
        Imprint
      </Link>
      <Link
        to="/privacy/"
        activeClassName={buttonstyles.active}
        className={`${styles.link} ${buttonstyle} ${buttonstyles.dimmed}`}
      >
        Privacy
      </Link>
    </div>
  )
}

export default Menu
