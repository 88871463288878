import React from 'react'
import PropTypes from 'prop-types'

import {
  createShader,
  createProgram,
  resizeCanvasToDisplaySize,
  uniforms,
  boundBuffer,
  readVertexAttrib,
  drawTriangles,
} from '../lib/webglutils'
import { concat } from '../lib/utils'

import vertexShaderSource from '../shaders/vertex-shader.glsl'
import fragmentShaderSource from '../shaders/fragment-shader.glsl'

import styles from './waveform.module.scss'

class Waveform extends React.Component {
  constructor(props) {
    super(props)
    this.bar = this.bar.bind(this)
    this.canvas = React.createRef()
    this.gl = null
    this.devicePixelRatio = 1
  }

  componentDidMount() {
    this.devicePixelRatio = window.devicePixelRatio
    this.gl =
      this.canvas.current.getContext('webgl') ||
      this.canvas.current.getContext('experimental-webgl')
    if (this.gl) {
      this.setupWebGLProgram()
      resizeCanvasToDisplaySize(this.canvas.current, this.devicePixelRatio)
      this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height)
      this.drawWaveform(this.props.playPosition)
    }
  }

  componentDidUpdate() {
    resizeCanvasToDisplaySize(this.gl.canvas, this.devicePixelRatio)
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height)
    this.drawWaveform(this.props.playPosition)
  }

  componentWillUnmount() {
    this.gl = null
  }

  setupWebGLProgram() {
    let gl = this.gl
    this.program = createProgram(
      gl,
      createShader(gl, gl.VERTEX_SHADER, vertexShaderSource),
      createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)
    )
    this.positionAttribLocation = gl.getAttribLocation(
      this.program,
      'a_position'
    )
    this.uniforms = uniforms(gl, this.program, {
      resolution: gl.uniform2f,
      totalSamples: gl.uniform1f,
      playPosition: gl.uniform1f,
      sampleMaxAbsVal: gl.uniform1f,
      horizontalStretch: gl.uniform1f,
      devicePixelRatio: gl.uniform1f,
    })
    let positions = this.props.waveform.data.map(this.bar).reduce(concat, [])
    this.positionBuffer = boundBuffer(this.gl, positions, Float32Array)
    this.gl.viewport(0, 0, this.gl.canvas.width, this.gl.canvas.height)
    this.gl.clearColor(0, 0, 0, 0)
  }

  drawWaveform(playPosition) {
    this.gl.clear(this.gl.COLOR_BUFFER_BIT)
    if (this.props.noDraw) {
      return
    }
    this.gl.useProgram(this.program)
    readVertexAttrib(this.gl, this.positionBuffer, this.positionAttribLocation)
    this.uniforms.resolution(
      this.gl.canvas.clientWidth,
      this.gl.canvas.clientHeight
    )
    this.uniforms.totalSamples(this.props.waveform.data.length)
    this.uniforms.playPosition(playPosition)
    this.uniforms.sampleMaxAbsVal(Math.pow(2, this.props.waveform.bits - 1))
    this.uniforms.horizontalStretch(this.props.horizontalStretch || 1)
    this.uniforms.devicePixelRatio(this.devicePixelRatio)
    drawTriangles(this.gl, this.props.waveform.data.length * 6)
  }

  render() {
    return (
      <canvas
        onClick={this.props.onClick}
        ref={this.canvas}
        className={styles.canvas}
      />
    )
  }

  bar(sample, i) {
    let leftX = i
    let rightX = i + (this.props.barWidth || 1)
    let topY = Math.min(sample, 0)
    let bottomY = Math.max(sample, 0)
    return [
      leftX,
      bottomY,
      leftX,
      topY,
      rightX,
      topY,
      leftX,
      bottomY,
      rightX,
      bottomY,
      rightX,
      topY,
    ]
  }
}

Waveform.propTypes = {
  waveform: PropTypes.object.isRequired,
  playPosition: PropTypes.number.isRequired,
  horizontalStretch: PropTypes.number,
  barWidth: PropTypes.number,
  noDraw: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default Waveform
