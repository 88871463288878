import React from 'react'
import PropTypes from 'prop-types'
import styles from './about.module.scss'
import buttonstyles from './button.module.scss'
import matches from '@sub-lunar/matches'
import { ReactComponent as Logo } from '../components/logo.svg'

class About extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      mousedown: null,
    }
  }

  mouseDownHandler(event) {
    this.setState({
      mouseDown: {
        x: event.clientX,
        y: event.clientY,
      },
    })
  }

  mouseUpHandler(event) {
    // do not close when an anchor is clicked or the mouse was moved

    const xDist = Math.abs(this.state.mouseDown.x - event.clientX)
    const yDist = Math.abs(this.state.mouseDown.y - event.clientY)

    if (xDist > 12 || yDist > 12) {
      return
    }

    if (matches(event.target, 'a')) {
      return
    }

    this.props.closeHandler()
  }

  render() {
    return (
      <div
        onMouseUp={event => this.mouseUpHandler(event)}
        onMouseDown={event => this.mouseDownHandler(event)}
        className={styles.overlay}
      >
        <div className={styles.content}>
          <p>
            <span className={styles.logo}>
              <Logo className={styles.logosvg} />
            </span>{' '}
            creates sounds for moving pictures. We compose music and work on
            sound design in Berlin, Germany. Our motivation is the special force
            that sounds have to drive unique films into unique directions.{' '}
            <span className={`${buttonstyles.overlay} ${styles.button} `}>
              Play Sound Reel
            </span>
          </p>
        </div>
      </div>
    )
  }
}

About.propTypes = {
  closeHandler: PropTypes.func.isRequired,
}

export default About
