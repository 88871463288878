import React from 'react'
import PropTypes from 'prop-types'
import styles from './timelinebutton.module.scss'

class TimelineButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isActive: false,
    }
  }

  showLabel() {
    this.setState({
      isActive: true,
    })
  }

  hideLabel() {
    this.setState({
      isActive: false,
    })
  }

  render() {
    return (
      <button
        type="button"
        className={`${styles.button} ${
          this.state.isActive ? styles.isActive : ''
        }`}
        style={{
          left: 100 * this.props.playPosition + '%',
        }}
        onClick={this.props.clickHandler}
        onTouchStart={() => this.showLabel()}
        onTouchEnd={() => this.hideLabel()}
      >
        <span className={styles.disc} />
        <div
          className={`${styles.label} ${this.props.index === 0 &&
            styles.firstLabel}`}
        >
          <div>{this.props.timestamp}</div>
          {TimelineButton.splitTitleDivs(this.props.title)}
        </div>
      </button>
    )
  }

  static splitTitleDivs(title) {
    return title
      .split('\n')
      .map((line, i) => <div key={i}>{TimelineButton.splitTagSpan(line)}</div>)
  }

  static splitTagSpan(line) {
    let match = line.match(/^(\w+:)( .+)/)
    if (match) {
      return (
        <>
          <span className={styles.tag}>{match[1]}</span>
          {match[2]}
        </>
      )
    }
    return line
  }
}

TimelineButton.propTypes = {
  playPosition: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  clickHandler: PropTypes.func.isRequired,
  timestamp: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default TimelineButton
