import React from 'react'
import PropTypes from 'prop-types'

import styles from './fullscreen-icon.module.scss'

const FullscreenIcon = props => (
  <div
    className={`${styles.fullscreenIcon} ${
      props.isFullscreen ? styles.isFullscreen : ''
    }`}
  >
    <div className={`${styles.bar} ${styles.bar1} ${styles.horizontalBar}`} />
    <div className={`${styles.bar} ${styles.bar2} ${styles.horizontalBar}`} />
    <div className={`${styles.bar} ${styles.bar3} ${styles.verticalBar}`} />
    <div className={`${styles.bar} ${styles.bar4} ${styles.verticalBar}`} />
    <div className={`${styles.bar} ${styles.bar5} ${styles.horizontalBar}`} />
    <div className={`${styles.bar} ${styles.bar6} ${styles.horizontalBar}`} />
    <div className={`${styles.bar} ${styles.bar7} ${styles.verticalBar}`} />
    <div className={`${styles.bar} ${styles.bar8} ${styles.verticalBar}`} />
  </div>
)

FullscreenIcon.propTypes = {
  isFullscreen: PropTypes.bool.isRequired,
}

export default FullscreenIcon
